import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    customer: {
      id: 0,
      phone: '',
      firstName: '',
      lastName: '',
      email: '',
      birthdate: '',
      gender: '',
      otherId: '',
      addressLine1: '',
      addressLine2: '',
      addressCity: '',
      addressState: '',
      addressZipcode: '',
    },
    useDate: '',
    heightFeet: '',
    heightInches: '',
    weight: '',
    fit: '',
    measurements: null,
    event : {
      id: 0,
      foreignKey: '',
      occasion: '',
      location: '',
      eventType: '',
      eventDate: '',
      coordinator1FullName: '',
      coordinator2FullName: '',
      coordinatorPhone: ''
    },
    eventRole: 0,
    outfit: null,
    action: 'rent',
    product: null,
    products: [],
    reservation: null,
    path: 'rent',
    promoCode: null,
    token: null,
  },
  mutations: {
    SET_CUSTOMER( state, payload ) {
      state.customer = payload;
    },
    SET_CUSTOMER_PROPERTY( state, payload ) {
      state.customer[payload.key] = payload.value;
    },
    SET_OUTFIT( state, payload ) {
      state.outfit = payload;
    },
    SET_OUTFIT_PROPERTY( state, payload ) {
      state.outfit[payload.key] = payload.value;
    },
    SET_MEASUREMENTS( state, payload ) {
      state.measurements = payload;
    },
    SET_EVENT( state, payload ) {
      state.event = payload;
    },
    SET_USE_DATE( state, payload ) {
      state.useDate = payload;
    },
    SET_HEIGHT_WEIGHT_FIT( state, payload ) {
      state.heightFeet = payload.heightFeet;
      state.heightInches = payload.heightInches;
      state.weight = payload.weight;
      state.fit = payload.fit;
    },
    SET_EVENT_ROLE( state, payload ) {
      state.eventRole = payload;
    },
    SET_PRODUCTS( state, payload ) {
      state.products = payload;
    },
    SET_ACTION( state, payload ) {
      state.action = payload;
    },
    SET_PRODUCT( state, payload ) {
      state.product = payload;
    },
    SET_PATH( state, payload ) {
      state.path = payload;
    },
    SET_RESERVATION( state, payload ) {
      state.reservation = payload;
    },
    SET_PROMO_CODE( state, payload ) {
      state.promoCode = payload;
    },
    SET_TOKEN( state, payload ) {
      state.token = payload;
    }
  },
  actions: {
    setCustomer( context, customer ) {
      context.commit( 'SET_CUSTOMER', customer );
    },
    setCustomerProp( context, obj ) {
      context.commit( 'SET_CUSTOMER_PROPERTY', obj );
    },
    clearCustomer( context ) {
      context.commit( 'SET_CUSTOMER', {
        phone: '',
        firstName: '',
        lastName: '',
        email: '',
        birthdate: '',
        gender: '',
        otherId: '',
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZipcode: '',
      });
    },
    setOutfit( context, outfit ) {
      context.commit( 'SET_OUTFIT', outfit );
    },
    clearOutfit( context ) {
      context.commit( 'SET_OUTFIT', null);
    },
    setMeasurements( context, measurements ) {
      context.commit( 'SET_MEASUREMENTS', measurements );
    },
    setEvent( context, event ) {
      context.commit( 'SET_EVENT', event );
    },
    clearEvent( context ) {
      context.commit( 'SET_EVENT', {
        id: 0,
        foreignKey: '',
        occasion: '',
        location: '',
        eventType: '',
        eventDate: '',
        coordinator1FullName: '',
        coordinator2FullName: '',
        coordinatorPhone: ''
      });
    },
    setUseDate( context, useDate ) {
      context.commit( 'SET_USE_DATE', useDate );
    },
    setHeightWeightFit( context, payload ) {
      context.commit( 'SET_HEIGHT_WEIGHT_FIT', payload );
    },
    setEventRole( context, role ) {
      context.commit( 'SET_EVENT_ROLE', role );
    },
    setProducts( context, products ) {
      context.commit( 'SET_PRODUCTS', products );
    },
    clearProducts( context ) {
      context.commit( 'SET_PRODUCTS', [] );
    },
    setAction( context, action ) {
      context.commit( 'SET_ACTION', action );
    },
    setProduct( context, product ) {
      context.commit( 'SET_PRODUCT', product );
    },
    clearStore( context ) {
      context.commit( 'SET_OUTFIT', null );
      context.commit( 'SET_USE_DATE', '' );
      context.commit( 'SET_PRODUCT', null );
      context.commit( 'SET_ACTION', 'rent' );
      context.commit( 'SET_EVENT', {
        id: 0,
        foreignKey: '',
        occasion: '',
        location: '',
        eventType: '',
        eventDate: '',
        coordinator1FullName: '',
        coordinator2FullName: '',
        coordinatorPhone: ''
      });
      context.commit( 'SET_CUSTOMER', {
        phone: '',
        firstName: '',
        lastName: '',
        email: '',
        birthdate: '',
        gender: '',
        otherId: '',
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZipcode: '',
      });
      context.commit( 'SET_HEIGHT_WEIGHT_FIT', {
        heightFeet    : '',
        heightInches  : '',
        weight        : '',
        fit           : ''
      });
      context.commit( 'SET_PATH', 'rent' );
      context.commit( 'SET_RESERVATION', null );
    },
    setPath( context, path ) {
      context.commit( 'SET_PATH', path );
    },
    setReservation( context, reservation ) {
      context.commit( 'SET_RESERVATION', reservation );
    },
    setPromoCode( context, promoCode ) {
      context.commit( 'SET_PROMO_CODE', promoCode );
    },
    setToken( context, token ) {
      context.commit( 'SET_TOKEN', token );
    }
  },
  getters: {
    customer      : (state) => state.customer,
    outfit        : (state) => state.outfit,
    event         : (state) => state.event,
    useDate       : (state) => state.useDate,
    heightFeet    : (state) => state.heightFeet,
    heightInches  : (state) => state.heightInches,
    weight        : (state) => state.weight,
    fit           : (state) => state.fit == '' ? 'slim' : state.fit,
    measurements  : (state) => state.measurements,
    eventRole     : (state) => state.eventRole,
    products      : (state) => state.products,
    action        : (state) => state.action,
    product       : (state) => state.product,
    path          : (state) => state.path,
    reservation   : (state) => state.reservation,
    promoCode     : (state) => state.promoCode,
    token         : (state) => state.token,
  },
  plugins: [createPersistedState()]
})
