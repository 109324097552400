<template>
    <v-dialog v-model="showing" :width="width" persistent>
        <v-card>
            <v-card-title>
                <v-icon v-show="icon.length > 0" left>mdi-{{ icon }}</v-icon>
                {{ title }}
            </v-card-title>
            <v-card-text :color="color" v-html="message">
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="close">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { bus } from '@/main';
import { _st } from '@/softech';

export default {
    props: {
        icon:           { type: String, default: '' },
        value:          { type: Boolean, default: false },
        width:          { type: String, default: '500' }
    },
    data: () => ({
        title       : '',
        message     : 'message',
        type        : '',
        showing     : false,
    }),
    mounted() {
        bus.$on( 'message', (message) => {
            this.show(message);
        });
    },
    computed: {
        color() {
            let c = '';
            switch( this.type ) {
                case 'message': c = ''; break;
                case 'warning': c = 'warning'; break;
                case 'error':   c = 'error'; break;
            }

            return c;
        }
    },
    methods: {
        show(message) {
            this.title = message.title;
            this.type = message.type;
            this.showing = true;

            if( _st.isArray( message.message ) ) {
                this.message = '<ul>';
                message.message.forEach(m => {
                    this.message += `<li>${m}</li>`;
                });
                this.message += '</ul>';
            }
            else
                this.message = message.message;
        },
        close() {
            this.showing = false;
        }
    }
}
</script>

<style scoped>

</style>