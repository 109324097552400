<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>

    <message />
  </v-app>
</template>

<script>
import Message from '@/components/Message.vue';

export default {
  mounted() {
    document.title = 'LFA Rental Shop';
  },
  components: { Message }  
}
</script>


<style lang="scss">

</style>
